<template>
  <!-- 供需关系 -->
  <div class="outer-page">
    <x-table
      :no-data-text="CA('supply_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
    ></x-table>
    <choose-Model
      v-model="supplyShow"
      :title="supplyTitle"
      :DefluteValue="finishId"
      @refrestList="refrestList"
    >
    </choose-Model>
    <supply-Model
      v-model="DriverModel"
      :title="DriverTitle"
      :DefluteValue="DefluteValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></supply-Model>
    <car-detail
      v-model="transportModel"
      :title="transportTitle"
      :DefluteValue="transporValue"
      :defaultEdeit="defaultEdeit"
      @refrestList="refrestList"
    ></car-detail>
  </div>
</template>

<script>
import moment from "moment";
import chooseModel from "./chooseModel";
import carDetail from "./orderModel";
import supplyModel from "./supplyModel";
export default {
  name: "",
  components: {
    chooseModel,
    carDetail,
    supplyModel,
  },
  data() {
    return {
      DriverModel: false,
      supplyShow: false,
      transportModel: false,
      transportTitle: "",
      transporValue: {},
      supplyTitle: "需求处理",

      finishId: 0,
      DriverTitle: "",
      DefluteValue: {},
      table: {
        columns: [
          {
            title: "来源地",
            minWidth: 100,
            key: "publisherFarmName",
          },
          {
            title: "需求名称",
            minWidth: 100,
            key: "demandName",
          },
          {
            title: "需求类型",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <div>{row.demandType == "0" ? "供应需求" : "采购需求"}</div>
              );
            },
          },
          {
            title: "供需备注",
            minWidth: 100,
            key: "demandRemark",
          },
          {
            title: "货物名称",
            minWidth: 100,
            key: "productName",
          },
          {
            title: "供应量描述",
            minWidth: 100,
            key: "demandNumber",
          },
          {
            title: "数量",
            minWidth: 100,
            key: "demandNum",
          },

          {
            title: "单位类型",
            minWidth: 100,
            key: "demandUnit",
          },
          {
            title: "供需图片",
            minWidth: 100,
            render: (h, { row }) => {
              if (!row.demandImgs || !row.servicePath) return;
              return (
                <img
                  src={row.servicePath + row.demandImgs}
                  class="flexboxb"
                  style="width: 60px;height:40px;"
                />
              );
            },
          },
          {
            title: "需求地址",
            minWidth: 100,
            key: "demandAddress",
          },
          {
            title: "联系人",
            minWidth: 100,
            key: "telName",
          },
          {
            title: "联系电话",
            minWidth: 120,
            key: "telPhone",
          },
          {
            title: "状态",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>{row.demandStatus == "1" ? "发布中" : "已处理"}</span>
              );
            },
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  {localStorage.userId == row.createUserId && (
                    <Poptip
                      confirm
                      transfer
                      title="确定结束需求吗?"
                      on-on-ok={() =>
                        row.demandStatus == "1" ? this.finishSupply(row.id) : ""
                      }
                    >
                      <a style="margin-right: 10px">
                        {row.demandStatus == "1" ? "结束需求" : ""}
                      </a>
                    </Poptip>
                  )}
                  {
                    //   this.CA("supply_update") && (
                    //   <a
                    //     style="margin-right: 10px"
                    //     onClick={() => this.edit(row)}
                    //   >
                    //     编辑
                    //   </a>
                    // )
                  }
                  {
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.checkInfo(row)}
                    >
                      需求详情
                    </a>
                  }
                  {localStorage.userId != row.createUserId && row.demandStatus != "2" && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.releaseOrder(row)}
                    >
                      下单
                    </a>
                  )}
                  {localStorage.userId == row.createUserId &&
                    row.demandStatus != "2" &&
                    this.CA("supply_delete") && (
                      <Poptip
                        confirm
                        transfer
                        title="确定删除吗?"
                        on-on-ok={() => this.delete(row.id)}
                      >
                        <a>删除</a>
                      </Poptip>
                    )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },

      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      form: {},
      defaultEdeit: false,
      deptList: [],
      search_data: {},
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "发布需求",
            ca: "demand_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "状态",
              component: "select",
              field: "demandStatus",
              defaultValue: "1",
              data: [
                { status: "1", statusName: "发布中" },
                { status: "2", statusName: "已处理" },
              ],
              parameterField: "status",
              showField: "statusName",
            },
          ],
        },
        page: {...this.page},
      }
      return config
    }
  },
  methods: {
    //发布订单
    releaseOrder(row) {
      this.transportModel = true;
      this.transportTitle = "发布订单";
      this.transporValue = row;
    },
    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },

    add() {
      this.DriverModel = true;
      this.DriverTitle = "添加供需";
      this.defaultEdeit = false;
      this.transporValue = {};
      this.DefluteValue = {};
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    refrestList() {
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.SUPPLY_BIND.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    edit(row) {
      this.defaultEdeit = false;
      this.DriverModel = true;
      this.DriverTitle = "修改需求";
      this.DefluteValue = row;
    },
    checkInfo(row) {
      this.defaultEdeit = true;
      this.DefluteValue = row;
      this.DriverModel = true;
      this.DriverTitle = "供需详情";
    },
    //需求处理列表
    finishSupply(id) {
      this.$post(this.$api.SUPPLY_BIND.FINISH, {
        id,
      })
        .then((res) => {
          this.$Message.success("结束需求成功");
          this.getList();
        })
        .catch((e) => {
          console.log(e);
        });
      // this.finishId = id;
      // this.supplyShow = true;
    },
    //删除
    delete(id) {
      this.$post(this.$api.SUPPLY_BIND.DELETE, {
        id: id,
      }).then(() => {
        this.$Message.success("删除成功！");
        this.getList();
      });
    },
    //获取部门列表
    getDeptList() {
      this.$post(this.$api.company.BARNCHTREE)
        .then((res) => {
          this.deptList = res;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.getDeptList();
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
}
</style>